import React from "react";
import { Link, useParams } from "react-router-dom";

export function ProjectListItem(props) {
  const { project } = props;
  const { projectUrl } = useParams();

  return (
    <>
      {projectUrl === project.url && (
        <Link to="/">
          <div className="show-less">Show less</div>
        </Link>
      )}
      <Link to={`/project/${project.url}`}>
        <div className="project-list-item box">
          <div>
            <p className={`show-more ${projectUrl === project.url && 'hidden'}`}>Show more</p>
            <p className="title">{project.title}</p>
            <p className="description">{project.description}</p>
          </div>
          <div>
            <img
              className="thumbnail"
              src={`/images/${project.thumbnailUrl}`}
              alt=""
            />
          </div>
        </div>
      </Link>
    </>
  );
}
