import React from "react";
import information from "../information.json";
import { ProjectListItem } from "./ProjectListItem";
import { Outlet, useParams } from "react-router-dom";

export function ProjectList() {
  const { projects } = information;

  const { projectUrl } = useParams();

  return (
    <div>
      {projects.map((project) => (
        <>
          <ProjectListItem key={project.url} project={project} />
          {projectUrl === project.url && (
            <div className="router-container only-tablet">
              <Outlet />
            </div>
          )}
        </>
      ))}
    </div>
  );
}
