import React, { useEffect, useRef } from 'react'

export default function About() {
  const inputElement = useRef(null);

  useEffect(() => {
    inputElement.current.scrollIntoView({ behavior: 'smooth' })
  }, [])

  return (
    <div className='about' ref={inputElement}>
      <div className='introduction'>
        <div>
          <div className='box text'>
            <h2>You can call me Pablo. I made up an alter ego called Burning Pablo, he's a devil — with horns and all, but he'll never do anything to harm any living being.</h2>

            <p>I'm a Colombian climber, plant-based human and brand developer living in Mexico City.</p>

            <p>Since I have memory, I like everything visual, which made me start drawing since very young. Years ago, I made up an alter ego called Burning Pablo. This character became a brand and a personal project where tons of creative ideas got released. At the same time, I've been working for several brands and companies, between creative agencies, brand boutiques, startups, fintech, and even foundations, and different countries like Colombia, Mexico and Canada.</p>
          </div>
          <div className='box contact-info text'>
            <div>
              <p className='contact-word'>Contact</p>
              <p>hola@pulidopablo.com</p>
            </div>
            <div className='media-links'>
              <a href="http://instagram.com/burningpablo" target='_blank' rel="noreferrer"><i className="fa-brands fa-instagram"></i></a>
              <a href="http://www.linkedin.com/in/burningpablo" target='_blank' rel="noreferrer"><i className="fa-brands fa-linkedin-in"></i></a>
              <a href="https://www.behance.net/BurningPablo" target='_blank' rel="noreferrer"><i class="fa-brands fa-square-behance"></i></a>
            </div>
          </div>
        </div>
        <div className='box animation'>
        </div>
      </div>
      <div className='mexico-story'>
        <div className='box'>
        </div>
        <div className='box margin-left'>
        </div>
      </div>
      <div className='mexico-images'>
        <div className='box'>
        </div>
        <div className='box margin-left'>
        </div>
      </div>
      <div className='calgary-story'>
        <div className='box'>
        </div>
        <div className='box margin-left'></div>
      </div>
      <div className='patineta'>
        <div className='box'></div>
      </div>
    </div>
  )
}
