import information from "../information.json";

const validateInformationFormat = () => {
  const { projects } = information;

  validateInformationCompletion(projects);
  validateUrlsUniqueness(projects);
  validateKeyWords(projects);
};

const validateKeyWords = (projects) => {
  const keyWords = ["about"];

  for (const project of projects) {
    if (keyWords.includes(project.url)) {
      throw new Error(
        "Project with url: " +
          project.url +
          ". Any of these urls can be used: " +
          keyWords.join(", ")
      );
    }
  }
};

const validateInformationCompletion = (projects) => {
  const rootKeysToCheck = [
    "url",
    "title",
    "description",
    "thumbnailUrl",
    "detailsPageBlocks",
  ];
  for (const project of projects) {
    for (const keyToCheck of rootKeysToCheck) {
      if (!project[keyToCheck]) {
        throw new Error("Project is missing: " + keyToCheck);
      }
    }
  }
};

const validateUrlsUniqueness = (projects) => {
  const existingUrls = new Set();

  for (const project of projects) {
    if (existingUrls.has(project.url)) {
      throw new Error("There is a duplicated projectUrl");
    }

    existingUrls.add(project.url);
  }
};

export { validateInformationFormat };
